import React from "react";

export const SuccessStatusIcon = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Status icons" clip-path="url(#clip0_104_4820)">
      <path id="Combined Shape" fill-rule="evenodd" clip-rule="evenodd" d="M32.0376 0C49.7103 0 64.0376 14.3273 64.0376 32C64.0376 49.6727 49.7103 64 32.0376 64C14.3649 64 0.0375977 49.6727 0.0375977 32C0.0375977 14.3273 14.3649 0 32.0376 0ZM46.2019 22.228C45.1592 21.188 43.4739 21.188 42.4312 22.228L27.3752 37.2867L21.4419 31.3507C20.3992 30.3107 18.7112 30.3107 17.6712 31.3507C16.6285 32.3933 16.6285 34.0813 17.6712 35.1213L25.4899 42.9427C25.9885 43.444 26.6685 43.724 27.3752 43.724C28.0819 43.724 28.7619 43.444 29.2605 42.9427L46.2019 26.0013C47.2445 24.9587 47.2445 23.2707 46.2019 22.228Z" fill="#0CAA8F"/>
    </g>
    <defs>
      <clipPath id="clip0_104_4820">
        <rect width="64" height="64" fill="white" transform="translate(0.0375977)"/>
      </clipPath>
    </defs>
  </svg>
);
