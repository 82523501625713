import { Text } from '@dls/web';
import styled from 'styled-components';
import media from '../helpers/media';

export const LoginHeading = styled(Text)`
  margin-bottom: 8px;
  font-size: 32px;
  ${media.sm`
    margin-bottom: 16px;
    font-size:36px;
`}
  text-align: center;
`;

LoginHeading.defaultProps = {
  type: 'pageTitle'
};

export const Heading = styled(Text)`
  margin-bottom: 8px;
  ${media.sm`
    margin-bottom: 16px;
  `}
  text-align: center;
`;

Heading.defaultProps = {
  type: 'pageTitle'
};

export const ModalText = styled(Text)`
  margin-bottom: 32px;
  ${media.sm`
    margin-top: 24px;
  `}
`;

export const BodyText = styled(Text)`
  text-align: center;
  margin-bottom: 32px;
  ${media.sm`
    margin-top: 24px;
  `}
`;

export const FieldWrapper = styled.div`
  & + & {
    margin-top: 16px;
    ${media.sm`
      margin-top: 24px;
    `}
  }

  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #f7f7f7 inset;
  }
  input:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
`;

export const ButtonWrapper = styled.div`
  &:first-child {
    margin-top: 32px;
    ${media.sm`
      margin-top: 32px;
    `}
  }

  & + & {
    margin-top: 16px;
    ${media.sm`
      margin-top: 24px;
    `}
  }
`;

export const StyledLink = styled(Text)`
  display: inline-block;
  text-align: center;
`;

StyledLink.defaultProps = {
  type: 'link'
};

export const CenterText = styled(Text)`
  text-align: center;
`;

/**
 * Since this application may be viewed in iframe,
 * "min-device-width" is used instead of "min-width"
 */

export const MobileText = styled(CenterText)`
  margin: auto;
  margin-bottom: 8px;

  @media only screen and (min-device-width: 992px) {
    display: none;
  }
`;

export const DesktopText = styled(CenterText)`
  margin: auto;
  margin-bottom: 8px;
  display: none;

  @media only screen and (min-device-width: 992px) {
    display: block;
  }
`;
