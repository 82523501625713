import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Column, Text } from '@dls/web';
import {
  Heading,
  BodyText
} from '../components/styles';
import media from '../helpers/media';
import Footer from '../components/Footer';
import { SuccessStatusIcon } from "../icons/SuccessStatusIcon";
import ColorLogoMobile from '@dls/assets/images/logo_singtel@2x.png';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
`;
const HeaderWrapper = styled.div`
  border-top: solid 4px #ED1A3D;
  box-shadow: rgba(0,0,0,.2) 0px 0px 7px 0px;

  div:first-child {
    max-width: 1200px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const BodyWrapper = styled(Grid)`
  max-width: 992px;
  ${media.sm`
    width: 100%;
  `}
`;

const LogoWrapper = styled(Column)`
  text-align: center;
  ${media.sm`
    text-align: left;
    padding: 0 0 0 20px;
  `}
`;

const LogoImage = styled.img`
  max-width: 120px;
  max-height: 120px;
`;

const ProfileComplete = () => {
  return (
    <PageWrapper>
      <HeaderWrapper>
        <Grid>
          <Row>
            <LogoWrapper xs={12} sm={4} md={4} style={{padding:'0px'}}>
              <LogoImage src={ColorLogoMobile} alt="Logo" />
            </LogoWrapper>
          </Row>
        </Grid>
      </HeaderWrapper>
    <BodyWrapper>
      <div style={{padding: '24px 0', textAlign: 'center'}}>
        <SuccessStatusIcon />
      </div>
    <Heading>Update successful</Heading>
    <BodyText as="div">
      <Text type="body">Return to your chat window with Shirley and log in again to continue your enquiry.</Text>
    </BodyText>
    </BodyWrapper>
    <Footer />
    </PageWrapper>
  );
};

export default ProfileComplete;
